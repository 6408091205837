<template>
  <div
    class="widgetContainer widgetContainer--center widgetContainer--scrollable bg-white personDetails">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <span class="icon-Arrow-big-left cancel" @click="backAction" />
        <p class="title">
          {{ $t('kyc_NavTitle') }}
        </p>
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form
        @keyup.enter.native="submitForm()"
        :model="user"
        :rules="rules.user"
        ref="user"
        @submit.prevent.native
        class="wise-form">
        <el-form-item
          class="is-no-asterisk"
          prop="firstName"
          :label="$t('fName')">
          <el-input v-model="user.firstName" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk"
          prop="lastName"
          :label="$t('lName')">
          <el-input v-model="user.lastName" />
        </el-form-item>
        <el-form-item class="is-no-asterisk" prop="email" :label="$t('email')">
          <el-input v-model="user.email" type="email" />
        </el-form-item>
        <el-form-item
          class="is-no-asterisk date-of-birth"
          prop="dateOfBirth"
          :label="$t('dob')">
          <el-date-picker
            format="MM-dd-yyyy"
            placeholder="MM-DD-YYYY"
            :picker-options="datePickerOptions"
            :default-value="minDate"
            v-model="user.dateOfBirth"
            value-format="yyyy-MM-dd"
            type="date" />
        </el-form-item>
        <el-form-item class="is-no-asterisk document-type" :label="$t('Document Type')">
          <el-select v-model="user.idType">
            <el-option v-for="d in documentTypes" :key="d.type" :value="d.type" :label="$t(d.type)" />
          </el-select>
        </el-form-item>
        <el-form-item class="is-no-asterisk" prop="idNumber" :label="$t(user.idType)">
          <SSNInput :id-type="user.idType" :country="country" :value="user.idNumber" @input="ssnChange" :autofocus="false" />
        </el-form-item>
      </el-form>
      <div class="widgetContainer__footer--fixed">
        <el-button type="primary" class="el-button__brand brand" @click="submitForm()" :disabled="isFormValid">
          {{ $t('kyc_NavRightButton_Title') }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import SSNInput from '@/components/SSNInput';
import { alphaAndSpaceRule } from '@/utils/form';
import { mapActions, mapGetters } from 'vuex';
import { format } from 'date-fns';
import kyc from '../mixins/kyc';
import { getCountryDetailsFromPhoneNumber, getSupportedCountries, getDocumentTypes } from '@/utils/env';

export default {
  name: 'KycPersonDetails',
  mixins: [kyc],
  components: {
    SSNInput
  },
  data() {
    return {
      datePickerOptions: {
        disabledDate(date) {
          const minDate = new Date();
          minDate.setFullYear(minDate.getFullYear() - 18);
          return date > minDate;
        }
      },
      isSsnValid: false,
      user: {
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        idNumber: '',
        idType: 'ssn'
      },
      rules: {
        user: {
          firstName: alphaAndSpaceRule('First Name', true),
          lastName: alphaAndSpaceRule('Last Name', true),
          email: [
            {
              required: true,
              message: 'Email is required',
              trigger: 'blur'
            },
            {
              type: 'email',
              message: 'Please provide a valid email address',
              trigger: 'blur'
            }
          ],
          dateOfBirth: [
            {
              required: true,
              message: 'Date of birth is required'
            }
          ],
          idNumber: [
            {
              required: true,
              message: 'Tax ID is required'
            }
          ]
        }
      }
    };
  },
  computed: {
    ...mapGetters('user', ['getPersonDetails']),
    minDate() {
      const minDate = new Date();
      minDate.setFullYear(minDate.getFullYear() - 18);
      let month = minDate.getMonth() + 1;
      month = ('' + month).length === 1 ? '0' + month : month;
      let date = minDate.getDate();
      date = ('' + date).length === 1 ? '0' + date : date;
      return minDate.getFullYear() + '-' + month + '-' + date;

    },
    isFormValid() {
      if (
        this.user.firstName &&
        this.user.lastName &&
        this.user.email &&
        this.user.dateOfBirth &&
        this.user.idNumber && this.isSsnValid
      ) {
        return false;
      } else {
        return true;
      }
    },
    country() {
      const details = getCountryDetailsFromPhoneNumber(this.getPersonDetails.phone);
      if(details && details.code)
        return details.code;
      
      return 'US';
    },
    selectedCountry() {
      const supportedCountries = getSupportedCountries();
      return supportedCountries.find(c => c.code === this.country);
    },
    documentTypes() {
      return getDocumentTypes();
    }
  },
  created() {
    /** @type {import('@/modules/user/store/state').userState['personDetails']} */
    const person = this.getPersonDetails;
    if (person && person.id) {
      const { firstName, lastName, idNumber, email, dateOfBirth, idType } = person;
      this.isSsnValid = true;
      this.user = {
        firstName,
        lastName,
        idNumber,
        email,
        dateOfBirth,
        idType: idType || 'ssn'
      };
    }
  },
  methods: {
    ...mapActions('user', ['updatePerson']),
    backAction() {
      this.goToPreviousScreen();
    },
    ssnChange(v) {
      if(v) {
        this.isSsnValid = v.isValid;
        this.user.idNumber = v.value;
      } else {
        this.isSsnValid = false;
      }
    },
    submitForm() {
      const person = this.getPersonDetails;

      this.$refs.user.validate((valid) => {
        if (!valid) return;

        /** @type {import('@/modules/user/store/state').userState['personDetails']} */
        if (person && person.id) {
          const loader = this.showLoader();
          const user = this.user;
          if (typeof user.dateOfBirth !== 'string')
            user.dateOfBirth = format(user.dateOfBirth, 'yyyy-MM-dd');
          this.updatePerson({
            personId: person.id,
            person: user
          })
            .then(() => {
              this.$router.push('/kyc/personAddress');
            })
            .catch((e) => {
              console.log(e);
              this.apiErrorMessage(e);
            })
            .finally(() => {
              loader.close();
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
  .widgetContainer { 
    &.personDetails {
      .date-of-birth {
        display: flex;
        flex-direction: column;

        .el-form-item__label {
          margin-right: auto;
        }

        .el-date-editor {
          min-width: 100%;
        }
      }
      .document-type .el-select {
        width: 100%;
      }
    }
  }
</style>
